
html,body{
width:100%;
height:100%;
}
body{
margin:0px;
padding:0px;
background-color: rgb(61, 100, 120);
/* color:rgb(54, 222, 222) */
}
